<template>
  <div v-loading="submitting" key="ncc" class="ncc-container">
    <div id="ncc" :class="classes" v-html="template" />
    <div class="ncc-container__actions">
      <div class="preview">
        <img v-if="card" :src="card" />
        <div v-else class="dummy">
          Você ainda não tem cartão salvo...
        </div>
      </div>
      <div class="buttons">
        <el-button
          type="success"
          @click="showConfirmationDialog = !showConfirmationDialog"
        >
          Salvar cartão Neopag
        </el-button>
        <el-button type="danger" @click="handleBack">
          Sair sem salvar
        </el-button>
      </div>
      <div v-show="showConfirmationDialog" class="neoCard-modal">
        <el-dialog :visible.sync="showConfirmationDialog">
          <div style="text-align: center;">
            <h3>
              Tem certeza que deseja salvar? <br />
              O seu cartão atual será alterado!
            </h3>
            <br />
            <el-button type="success" @click="submitCard">
              Sim, quero salvar
            </el-button>
            <el-button
              type="danger"
              @click="showConfirmationDialog = !showConfirmationDialog"
            >
              Voltar
            </el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import * as types from "@/store/types";
import { mapGetters } from "vuex";

const PLUGIN_OPTS = {
  stageWidth: 720,
  stageHeight: 380,
  langJSON: "/ncc-lang.json",
  templatesDirectory: "/html/",
  mainBarModules: ["products", "images", "text", "manage-layers"],
  fonts: [
    {
      name: "Montserrat",
      url: "google"
    },
    {
      name: "Helvetica"
    },
    {
      name: "Times New Roman"
    },
    {
      name: "Arial"
    }
  ],
  customTextParameters: {
    colors: true,
    removable: true,
    resizable: true,
    draggable: true,
    rotatable: true,
    autoCenter: true,
    boundingBox: "Base"
  },
  customImageParameters: {
    draggable: true,
    removable: true,
    resizable: true,
    rotatable: true,
    autoCenter: true,
    boundingBox: "Base",
    boundingBoxMode: "clipping",
    maxH: 10000,
    maxW: 10000,
    minH: 1,
    minW: 1,
    minDPI: 1
  },
  actions: {
    bottom: ["undo", "redo", "download", "print", "snap"],
    right: [
      "info",
      "magnify-glass",
      "zoom",
      "reset-product",
      "qr-code",
      "ruler"
    ]
  }
};

export default {
  data() {
    const isBlackMap = [
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      -1,
      0,
      1
    ];
    const template = Array.from(Array(19).keys())
      .map(it => ++it)
      .reduce((acc, cur) => {
        const product = `
            <div
              class="fpd-product"
              title="Modelo ${cur}"
              data-thumbnail="/img/ncc/previews/card_${cur}.png">
              <img
                src="/img/ncc/bases/card_${cur}.png"
                title="Base"
                data-parameters='{"autoCenter": true, "colors": "#ffffff",  "boundingBoxMode": "clipping"}'>
              <img
                src="/img/ncc/logos/${
                  isBlackMap[cur - 1] === -1
                    ? "colorido"
                    : isBlackMap[cur - 1]
                    ? "preto"
                    : "branco"
                }.png"
                title="Neopag"
                data-parameters='{"topped": true, "left": 510, "top": 280 }'>
            </div>
          `;
        return acc + product;
      }, "");

    return {
      showConfirmationDialog: false,
      submitting: false,
      ncc: undefined,
      opt: PLUGIN_OPTS,
      classes:
        "fpd-container fpd-shadow-1 fpd-topbar fpd-tabs fpd-tabs-side fpd-top-actions-centered fpd-bottom-actions-centered fpd-views-inside-left",
      template
    };
  },
  computed: {
    ...mapGetters({
      authOrigin: types.AUTH_ORIGIN,
      store: types.STORE_INFO,
      activeStore: types.AUTH_ACTIVE_STORE_DETAILS
    }),
    card() {
      return this.store.card || false;
    }
  },
  async created() {
    this.$bus.$emit("closeMenu");
    try {
      await this.$store.dispatch(types.STORE_INFO, this.activeStore.id);
    } catch (error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
  },
  mounted() {
    if (document.head.querySelector(`#ncc-script`)) return this.init();
    const script = document.createElement("script");
    script.src = "/ncc-vendors.js";
    script.id = "ncc-script";
    script.onload = this.init;
    document.head.appendChild(script);
  },
  methods: {
    init() {
      let $vm = this;
      /*global $*/
      let $ncc = $("#ncc");
      /*global jQuery*/
      jQuery(document).ready(function() {
        setTimeout(() => {
          /*global FancyProductDesigner*/
          $vm.ncc = new FancyProductDesigner($ncc, $vm.opt);
        }, 1000);
      });
    },
    dataURItoBlob(dataURI) {
      const mime = dataURI
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];
      var sliceSize = 1024;
      var base64ImageContent = dataURI.replace(
        /^data:image\/(png|jpg);base64,/,
        ""
      );
      var byteChars = window.atob(base64ImageContent);
      var byteArrays = [];

      for (
        var offset = 0, len = byteChars.length;
        offset < len;
        offset += sliceSize
      ) {
        var slice = byteChars.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, { type: mime });
    },
    submitCard() {
      this.submitting = true;
      this.getDataURL();
      this.showConfirmationDialog = !this.showConfirmationDialog;
      if (this.authOrigin === 'app') {
        NativeAppChannel.postMessage('card-updated');
      }
    },
    print() {
      this.ncc?.print();
      return false;
    },
    createImg() {
      return this.ncc?.createImage();
    },
    getData() {
      return this.ncc?.getProduct();
    },
    getDataURL() {
      this.ncc?.getViewsDataURL(
        async url => {
          const blob = this.dataURItoBlob(url[0]);
          const fd = new FormData();
          fd.append("card", blob, "cartao-neopag.png");
          try {
            await this.$store.dispatch(types.STORE_CARD_UPDATE, {
              storeID: this.store.id,
              payload: fd
            });
            this.submitting = false;
          } catch (error) {
            if (this.$raven && error.status === 500) {
              this.$raven.captureException(error.message, { req: error });
            }
            this.$notify.error({ title: error.message });
          }
        },
        "transparent",
        {
          format: "png",
          multiplier: 2,
          width: 505,
          height: 318.5,
          top: 30,
          left: 108
        }
      );
    },
    handleBack() {
      if (this.authOrigin === 'app') {
        return NativeAppChannel.postMessage('card-updated');
      }
      if (this.onBack) {
        return this.onBack();
      }
      this.$router.push({
        path: "",
        name: "Configurações",
        meta: {
          params: {
            tab: "config"
          }
        }
      });
    }
  }
};
</script>
<style lang="scss">
@import "/ncc-style.css";
.ncc-container {
  width: 100%;
  #ncc {
    margin: 40px auto;
    width: 720px;
    min-height: 465px;
    max-width: 100%;
  }

  &__actions {
    margin: 40px auto;
    width: 720px;
    max-width: 100%;
    display: flex;
    flex-flow: row wrap;
    .preview,
    .buttons {
      flex: 0 50%;
      .el-button + .el-button {
        margin-left: 0 !important;
      }
      @media (max-width: 512px) {
        flex: 0 100%;
        padding: 1rem;
      }
    }
    .buttons {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      align-items: center;
      .el-button--danger {
        margin-top: 1rem;
      }
    }
    .preview .dummy {
      width: 360px;
      height: 227px;
      border-radius: 8px;
      background-color: rgba(#444, 0.25);
      font-family: $title-font;
      line-height: 227px;
      text-align: center;
      color: $preto;
      font-size: 0.95rem;
    }
    .preview img {
      width: 100%;
    }
  }
}
</style>
