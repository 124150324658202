<template>
  <div class="cartao-neopag">
    <ncc />
  </div>
</template>

<script>
import Ncc from "@/components/NCC/main.vue";

export default {
  components: {
    Ncc
  }
};
</script>
